import styled from '@xstyled/styled-components'

export const Wrapper = styled.div`
  font-size: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 70vh;
  padding: 40px 0;
`
export const InnerWrapper = styled.div`
  padding: 0 40px;
`

export const ContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  padding: 0 95px 0 0;

  p {
    margin: 20px 0;
  }

  li {
    margin-left: 16px;
  }
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  position: fixed;
  top: 0;
  left: 50%;
  height: 100vh;
  width: 50vw;
`

export const RightTop = styled.div`
  flex: 1;
  width: 100%;
`

export const RightBottom = styled.div`
  background-color: #466b5a;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CaseBox = styled.div`
  width: 512px;
`

export const CaseBoxMobile = styled.div``

export const MobileImage = styled.div`
  width: 100%;
  display: flex;
  div {
    background-size: contain;
    width: 100%;
    padding-bottom: 80%;
    margin-top: -40px;
  }
`

export const CaseHeader = styled.h3`
  font-size: 18px;
`

export const CaseText = styled.p`
  margin: 20px 0;
  font-size: 29px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: bold;
`

export const CaseSubtext = styled.p`
  font-size: 16px;
`

export const QuoteBox = styled.div`
  padding: 0 20px;
  border-left: 4px solid #fff;
  margin-bottom: 1rem;
`

export const Quote = styled.div`
  font-size: 29px;
  margin-bottom: 32px;
`

export const QuoteName = styled.div`
  font-size: 16px;
`

export const Wrap = styled.div`
  h1 {
    font-size: 17px;
  }
  blockquote {
    padding: 0 20px;
    border-left: 4px solid #fff;
    font-size: 29px;
    margin-bottom: 32px;
    p:last-child {
      font-size: 16px;
    }
  }
`
