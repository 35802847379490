import React, { useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import styled, { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Left, MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import { APP_SWITCH_BACKGROUND_MEDIA } from '@/events'
import PubSub from 'pubsub-js'

// @TODO fix this path
import {
  Wrapper,
  InnerWrapper,
  ContentWrapper,
  RightWrapper,
  RightTop,
  RightBottom,
  CaseBox,
  CaseHeader,
  CaseText,
  CaseSubtext,
  Wrap,
  CaseBoxMobile,
  MobileImage,
} from '../../pages/home/testimonials/styled'

const StyledRightTop = styled(RightTop)<{ imageUrl }>`
  background: ${(props) => props.imageUrl && `url(${props.imageUrl})`} 50% 50%
    no-repeat;
  background-size: cover;
`

export const query = graphql`
  query defaultCmsCase($id: String) {
    cmsCase(id: { eq: $id }) {
      title
      client_quote_gender
      client_header
      client_text
      client_footer
      article
      logo {
        data {
          url
        }
      }
      background_image {
        data {
          url
        }
      }
    }
  }
`

interface CaseData {
  cmsCase: GatsbyTypes.CmsCase
}

const DefaultCasePage: React.FC<{ data: CaseData }> = ({ data }) => {
  const pageData = data.cmsCase
  const inProp = useContentInProp()

  useLayoutEffect(() => {
    PubSub.publish(APP_SWITCH_BACKGROUND_MEDIA, {
      src: '/images/cases/content-bg.jpg',
      focusPoint: { x: 0.5, y: 0.5 },
    })

    document.body.classList.add('content')
    return () => {
      document.body.classList.remove('content')
    }
  }, [])

  const LeftContent = (
    <Wrap>
      <article
        dangerouslySetInnerHTML={{
          __html: pageData.article,
        }}
      />
    </Wrap>
  )

  const RightContent = (
    <CaseBox>
      <CaseHeader>{pageData.client_header}</CaseHeader>
      <CaseText>{pageData.client_text}</CaseText>
      <CaseSubtext>{pageData.client_footer}</CaseSubtext>
    </CaseBox>
  )

  const RightMobileContent = (
    <CaseBoxMobile>
      <CaseHeader>{pageData.client_header}</CaseHeader>
      <CaseText>{pageData.client_text}</CaseText>
      <CaseSubtext>{pageData.client_footer}</CaseSubtext>
    </CaseBoxMobile>
  )
  const backgroundImage =
    pageData.background_image && pageData.background_image.data.url

  return (
    <>
      <Left>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url="/clients-and-cases/cases"
            text="back"
          />
        )}
      </Left>
      <Page
        content={
          <PageWrapper noPaddingMobile>
            <WithSlideShowCheck
              mobileContent={[
                <Wrapper>
                  <MobileImage>
                    <StyledRightTop imageUrl={backgroundImage} />
                  </MobileImage>
                  <InnerWrapper>
                    {RightMobileContent}
                    {LeftContent}
                  </InnerWrapper>
                </Wrapper>,
              ]}
            >
              <Box row>
                <Box col={0.5}>
                  <ContentWrapper>{LeftContent}</ContentWrapper>
                </Box>
                <Box col={0.5} display="flex" justifyContent="center">
                  <RightWrapper>
                    <StyledRightTop imageUrl={backgroundImage} />
                    <RightBottom>{RightContent}</RightBottom>
                  </RightWrapper>
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/clients-and-cases/cases"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default DefaultCasePage
